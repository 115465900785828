import { createDropzone } from "@solid-primitives/upload";
import { type Component, type Setter, Show, createSignal, onCleanup, onMount } from "solid-js";
import { usePromptContext } from "../PromptContext";

export const ChatFullScreenFileDrop: Component<{
  openChatFileUpload: () => void;
  context: "thread" | "project";
}> = (props) => {
  const [show, setShow] = createSignal(false);

  onMount(() => {
    const show = (e: DragEvent) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      setShow(true);
    };
    window.addEventListener("dragenter", show);
    onCleanup(() => {
      window.removeEventListener("dragenter", show);
    });
  });
  return (
    <Show when={show()}>
      <ChatFullScreenFileDropZone {...props} setShow={setShow} />
    </Show>
  );
};

/**
 * This needed to be separated in a separate component because
 * dropzoneRef doesn't work with new refs changing old refs, therefore
 * we need to create a whole new instance of `createDropzone` whenever we
 * need the dropzone
 */
const ChatFullScreenFileDropZone: Component<{
  openChatFileUpload: () => void;
  context: "thread" | "project";
  setShow: Setter<boolean>;
}> = (props) => {
  const { uploader, activeCollection } = usePromptContext();

  /**
   * Once files are dropped, this component's job is done and it gets hidden
   * The ChatFileUpload is then opened so that it can show the upload progress
   */
  const { setRef: dropzoneRef } = createDropzone({
    onDrop: (files) => {
      const col = activeCollection();
      if (!col) {
        throw Error("No active collection.");
      }
      props.openChatFileUpload();
      uploader.addAssets(files, col.id, col.organizationId, col.tenantId);
      props.setShow(false);
    },
  });

  return (
    <div
      class="fixed inset-0 bg-indigo-1100/90 z-max p-10"
      ref={(e) => dropzoneRef(e)}
      onDragLeave={() => props.setShow(false)}
    >
      <div class="flex h-full flex-col items-center justify-center p-6 border-4 border-indigo-700 dark:border-slate-600 border-dashed rounded-lg">
        <svg
          class="w-10 h-10 mt-3 mb-3 text-indigo-300"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>
        <p class="mb-2 text-white text-lg">Drop to upload</p>
      </div>
    </div>
  );
};
